.project__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.project__cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.project__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2.5rem;
  width: 100%;  
}

.project__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.project__img__container {
  width: 10%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.project__data__container {
  width: 90%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

}

.project__data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.project__titles {
  width: 60%;
}

.project__duration {
  width: 40%;
  text-align: right;
}

.skillcards{
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.skillcard{
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  text-align: center;
  border: 2px solid transparent; /* Start with a transparent border */
  transition: border-color 0.3s ease; /* Add a transition for the border-color property */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 20%;
}

.skillcard:hover {
  background: transparent;
  border-color: var(--color-primary); /* Change the border color on hover */
  cursor: default;
}

/* Small screens */
@media screen and (max-width: 640px) {
  .project__card {
    flex-direction: column;
    gap: 1rem;
  }

  .project__data {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .project__titles, .project__duration {
    width: 100%;
    text-align: center;
  }

  .project__img__container {
    width: 100%;
  }
}